import React from "react"
import { Layout } from "@/layout"
import {
  Box,
  SEO,
  Width,
  Link,
  Text,
  Instagram,
  Facebook,
  MentorFooter,
  MentorHeader,
} from "@/components/index"
import Img from "gatsby-image"

export default function Mentorship({ data }) {
  return (
    <Layout headerType="checkout" headerTheme="light" footerTheme="light">
      <SEO
        title="Meet Sonora’s Mentors: Nicholas Veinoglou"
        description="Sonora pairs world-class mentors with intermediate guitar students in an intensive 3-month program to help them find their breakthrough on guitar."
      />
      <MentorHeader
        name="Nicholas Veinoglou"
        videoUrl="https://vimeo.com/498097315/1e462eb83e"
      />
      <Width pt="3.6rem" pb="7.2rem">
        <Box
          width={["100%", null, 10 / 12, null]}
          mx="auto"
          display="flex"
          flexDirection={["column", null, "row", null]}
        >
          <Box width={["100%", null, 4 / 12, null]} pr="2em">
            <Box borderRadius=".6rem" overflow="hidden">
              <Img
                alt={"nicholas"}
                fluid={data.nicholasVeinoglouImage.childImageSharp.fluid}
              />
            </Box>
          </Box>

          <Box width={["100%", null, 8 / 12, null]}>
            {" "}
            <Text as="h1" fontWeight="bold" mb="1.2rem" mt={["2rem", null, "0rem", null]}>
              Nicholas Veinoglou
            </Text>
            <Text level="1" mb="1.2rem">
              Touring and session guitarist
            </Text>
            <Box>
              <Link to="https://www.instagram.com/nicholasveinoglou" as="a">
                <Instagram mr="1.2rem" />
              </Link>
              <Link to="https://www.facebook.com/NicholasVeinoglou" as="a">
                <Facebook />
              </Link>
            </Box>
            <Box>
              <Text level="2" mt="3.6rem">
                Nicholas Veinoglou is a touring/session guitarist, musical
                director, and producer based in Los Angeles, CA. He has
                completed over 10 tours nationally and internationally, playing
                with pop stars such as Bazzi and Jordan Fisher. While touring,
                Nicholas has performed at many notable venues and festivals such
                as Madison Square Garden, The Forum, Radio City Music Hall,
                Coachella, Lollapalooza, Governors Ball, Bonnaroo, Austin City
                Limits; as well as TV appearances on Jimmy Fallon, The Ellen
                Show, Jimmy Kimmel, Good Morning America, Dick Clark, Elvis
                Durand, and many more. He has opened for Artists like Justin
                Timberlake, Camila Cabello, Shawn Mendez, and Dua Lipa while on
                tour. He has also worked as a pit musician under the direction
                of Bebe Winans for the playwright “Born For This”. Nicholas has
                recorded for artists such as Ann-Marie, Leven Kali, Alec King,
                and Nikki at famous studios such as The Village, United
                Recording, and East West Studios.
              </Text>
            </Box>
            <Box>
              <Text level="2" mt="3.6rem">
                Nicholas graduated Magna Cum Laude in May 2015 from Berklee
                College of Music with a Bachelor’s Degree in Jazz Guitar
                Performance and a minor in Harmony. An avid educator, he has
                taken on hundreds of students across the globe, held
                masterclasses at colleges and universities, and released hours
                of lesson material with Pickup music.
              </Text>
              <Text level="2" mt="3.6rem">
                Nicholas also works closely with some of the biggest music
                companies such as Fender, Gibson, Paul Reed Smith, Taylor,
                Harmony Guitars, D’addario, Keeley Electronics, Walrus Audio,
                Benson Amps, GFI Systems, Orangewood Guitars, Bullet Cable,
                Reunion Blues, and many more. He is frequently hired to demo new
                products for Fender and Taylor. Nicholas has amassed millions of
                views across his social media platforms, with over 60k active
                followers. He released his first album “Love Languages” in 2015,
                as well as solo guitar and lofi albums under the aliases “Donut
                Boy” and “Lo Light” in 2019.
              </Text>
            </Box>
          </Box>
        </Box>
      </Width>
      <MentorFooter data={data} name="Nicholas Veinoglou" />
    </Layout>
  )
}

/* Image and Other GQL Queries */
export const mentorshipQuery = graphql`
  query {
    nicholasVeinoglouImage: file(
      relativePath: { eq: "nicholasVeinoglou-L.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 824, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    levelupImage: file(relativePath: { eq: "levelup.png" }) {
      childImageSharp {
        fluid(maxWidth: 900, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    curriculumImage: file(relativePath: { eq: "curriculum.png" }) {
      childImageSharp {
        fluid(maxWidth: 570, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    communityImage: file(relativePath: { eq: "community.png" }) {
      childImageSharp {
        fluid(maxWidth: 800, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    locationImage: file(relativePath: { eq: "location.png" }) {
      childImageSharp {
        fixed(width: 14, quality: 100) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`
